import React, { useState, useCallback, useEffect } from "react";
import { cx } from "emotion";
import { IncFaIcon, IncFaIconName } from "../Icons";

export interface CollapsibleProps {
  header: string;
  content: React.ReactNode;

  className?: string;
  headerClassName?: string;

  show?: boolean;
  collapsible?: boolean;

  collapseIconName?: IncFaIconName;
  expandIconName?: IncFaIconName;
  iconPosition?: "left" | "right";
  headerActions?: JSX.Element;
}

const IncCollapsible: React.FC<CollapsibleProps> = (props: CollapsibleProps) => {
  const {
    header,
    content,
    className: pClassName = "",
    headerClassName: pHeaderClassName = "",
    collapseIconName,
    expandIconName,
    headerActions,
    collapsible = true,
    show = false,
    iconPosition = "left"
  } = props;
  const [open, setOpen] = useState(show);

  useEffect(() => {
    setOpen(show);
  }, [show]);

  const collapseicon = collapseIconName ? collapseIconName : "chevron-up";
  const expandIcon = expandIconName ? expandIconName : "chevron-down";

  const toggleOpen = useCallback(() => setOpen(prev => !prev), []);
  const className = cx("inc-collapsible", {
    [pClassName]: Boolean(pClassName)
  });
  const headerClassName = cx("header flex-gap-8", {
    [pHeaderClassName]: Boolean(pHeaderClassName)
  });

  return (
    <div className={className}>
      {header && (
        <div
          className={headerClassName}
          onClick={collapsible ? toggleOpen : undefined}
        >
          {collapsible && iconPosition === "left" && (
            <IncFaIcon
              className="icon"
              iconName={open ? collapseicon : expandIcon}
            />
          )}

          <div className="inc-flex-row inc-flex-center-vertical inc-text-subtext-medium">{header}</div>
          {headerActions}
          {collapsible && iconPosition === "right" && (
            <IncFaIcon
              className="icon"
              iconName={open ? collapseicon : expandIcon}
            />
          )}
        </div>
      )}

      {open && <div className="marginLt12 inc-flex-column flex-gap-12">{content}</div>}
    </div>
  );
};

export default IncCollapsible;
