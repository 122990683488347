import React, { useMemo } from "react";
import { cx } from "emotion";
import { generateId } from "../../utils";
import { IncFaIcon, IncInfoIcon } from "../Icons";
import { IncToolTip } from "../antd-components";
import { IncSwitchOption } from "./types";

export interface IncSwitchProps {
  options: IncSwitchOption[];
  value: IncSwitchOption;
  label?: string;
  onChange: (value: IncSwitchOption) => void;
  labelSize?: "regular" | "small";
  asPills?: boolean;
}

interface SwitchLabelProps {
  label: string;
  className?: string;
  helpTextId?: string;
  helpText?: string;
  required?: boolean;
}

export const IncSwitchLabel = (props: SwitchLabelProps) => {
  const { label, className, helpText, helpTextId, required } = props;
  const appliedClassName = `inc-flex-row inc-text-subtext-medium inception-select-label-container ${className || ""}`;

  return (
    <div className={appliedClassName}>
      {label && <div>{label}</div>}
      {required && <span className="inc-span-required-star">*</span>}
      {(helpTextId || helpText) && (
        <IncToolTip
          placement="top-start"
          showArrow
          titleId={helpTextId}
          titleText={helpText}
        >
          <div>
            <IncInfoIcon />
          </div>
        </IncToolTip>
      )}
    </div>
  );
};

export const IncSwitch: React.FC<IncSwitchProps> = props => {
  const { options, value, label = "", onChange, labelSize = "small", asPills = false } = props;

  const selectedValue = value?.value;
  const uid = useMemo(() => generateId(), []);

  const fontSizeLabel = labelSize === "small" ? "subtext" : "body";

  const className = cx("switch-container", {
    "as-pills": asPills
  });

  const selectedOption = useMemo(() => {
    let selOpt = options.find(({ value }) => value === selectedValue);
    selOpt = selOpt || options[0];
    return selOpt;
  }, [options, selectedValue]);

  const SwitchContainers = options.map(option => {
    const { isDisabled, label, value, iconName, icon, infoText } = option;

    const isOptionSelected = option === selectedOption;

    const key = `${uid}${value}`;
    const className = cx("switch", {
      "switch--active": isOptionSelected,
      "switch--disable": isDisabled
    });

    const onClick = () => {
      if (!isDisabled) {
        const selOption = { ...option };
        onChange(selOption);
      }
    };

    const labelClassName = cx("switch-label", "inc-flex-row inc-flex-center-vertical flex-gap-6", {
      [`inc-text-${fontSizeLabel}-medium`]: isOptionSelected,
      [`inc-text-${fontSizeLabel}`]: !isOptionSelected
    });

    return (
      <div
        className={className}
        key={key}
        onClick={onClick}
      >
        {Boolean(label || icon || iconName) && (
          <div
            className={labelClassName}
            title={label}
          >
            {iconName && <IncFaIcon iconName={iconName} />}
            {Boolean(icon) && (
              <>
                {typeof icon === "string" && <IncFaIcon iconName={icon} />}
                {typeof icon === "object" && icon}
              </>
            )}
            {Boolean(label) && label}

            {Boolean(infoText) && (
              <IncToolTip
                placement="top"
                showArrow
                titleText={infoText}
              >
                <div className="inc-flex-row">
                  <IncFaIcon
                    className="marginLt6"
                    iconName="info-circle"
                  />
                </div>
              </IncToolTip>
            )}
          </div>
        )}
      </div>
    );
  });

  return (
    <div className="inc-switch-container inc-flex-grow mr-2 inc-flex-column">
      {Boolean(label) && (
        <div className="inc-flex-row inc-flex-center-vertical marginBt8 inc-label-common">{label}</div>
      )}
      <div
        className={className}
        data-cy="toggle-bar"
      >
        {SwitchContainers}
      </div>
    </div>
  );
};
