import { cx } from "emotion";
import React, { CSSProperties, forwardRef, useCallback, useMemo } from "react";
import { getIconSpriteId, IncFaIconName } from "./IncFaIconsList";
import { IncFaIconProps } from "./types";

const IncFaIcon = forwardRef<HTMLDivElement, IncFaIconProps>((props, ref) => {
  const {
    iconName,
    className: pClassName = "",
    variant = "solid",
    stackIconName,
    color,
    spin = false,
    spinPulse = false,
    spinReverse = false,
    pulse = false,
    beat = false,
    fade = false,
    beatFade = false,
    bounce = false,
    shake = false,
    flip = null,
    size = null,
    pull = null,
    rotation = null,
    regular = false,
    style: pStyle,
    ...restProps
  } = props;

  const style: CSSProperties = useMemo(
    () =>
      color
        ? {
            ...(pStyle || {}),
            color
          }
        : pStyle || {},
    [color, pStyle]
  );

  const computedVariant = regular ? "regular" : variant;

  const className = useMemo(
    () =>
      cx("fa svg-inline--fa", {
        [pClassName]: Boolean(pClassName),
        "fa-spin": spin,
        "fa-spin-pulse": spinPulse,
        "fa-spin-reverse": spinReverse,
        "fa-pulse": pulse,
        "fa-beat": beat,
        "fa-fade": fade,
        "fa-beat-fade": beatFade,
        "fa-bounce": bounce,
        "fa-shake": shake,
        [`fa-flip-${flip}`]: Boolean(flip),
        [`fa-${size}`]: typeof size === "string" && Boolean(size),
        [`fa-pull-${pull}`]: Boolean(pull),
        [`fa-rotate-${rotation}`]: Boolean(rotation)
      }),
    [beat, beatFade, bounce, fade, flip, pClassName, pull, pulse, rotation, shake, size, spin, spinPulse, spinReverse]
  );

  const getSvgUrlAndViewBox = useCallback(
    (iconName: IncFaIconName) => {
      iconName = (iconName.startsWith("fa-") ? iconName.replace("fa-", "") : iconName) as IncFaIconName;
      const { iconSpriteId, iconVariants, viewBox } = getIconSpriteId(iconName);
      const finalVariant = iconVariants.includes(computedVariant) ? computedVariant : iconVariants[0];

      return {
        spriteUrl: `/bicycle-ai-kdev-resources/fa-icon-sprite/${finalVariant}.svg#${iconSpriteId}`,
        viewBox
      };
    },
    [computedVariant]
  );

  const baseIcon = useMemo(() => {
    const { spriteUrl, viewBox } = getSvgUrlAndViewBox(iconName);
    return (
      <svg
        {...restProps}
        className={className}
        fill={color || "currentColor"}
        stroke={color || "currentColor"}
        style={style}
        viewBox={viewBox}
      >
        <use xlinkHref={spriteUrl} />
      </svg>
    );
  }, [className, color, getSvgUrlAndViewBox, iconName, restProps, style]);

  const stackIcon = useMemo(() => {
    if (!stackIconName) {
      return <></>;
    }

    const { spriteUrl, viewBox } = getSvgUrlAndViewBox(stackIconName);
    const style: CSSProperties = {
      ...(pStyle || {}),
      position: "absolute",
      left: -1,
      top: 2
    };

    return (
      <svg
        {...restProps}
        className={className}
        fill={color || "currentColor"}
        stroke={color || "currentColor"}
        style={style}
        viewBox={viewBox}
      >
        <use xlinkHref={spriteUrl} />
      </svg>
    );
  }, [className, color, getSvgUrlAndViewBox, pStyle, restProps, stackIconName]);

  if (!stackIconName) {
    return baseIcon;
  }

  return (
    <div
      className="fa-layers display-inline-block position-relative"
      ref={ref}
    >
      {baseIcon}
      {stackIcon}
    </div>
  );
});

export default IncFaIcon;
