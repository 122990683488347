import { IncSelectOption } from "@inception/ui";
import { fetchEntityPropertiesForIds, ENTITY_TAG, pluralizeWord, FieldPickerUtils } from "../../../../utils";
import {
  BizDataQuery,
  PostAggProjection,
  TimeObj,
  UserServiceField,
  UserServiceFieldMetricConfigDefinition,
  WidgetConfigDTO,
  WidgetConfigUtils,
  exploreApiService
} from "../../../../services/api/explore";
import { getLabelForTimeObj } from "../../../../utils/DurationUtils";
import { CompareOperator } from "../../../../services/api/operationalise";
import { USFieldWidgetUtils } from "../../../../dashboard/widgets/USField/USFieldWidgetUtils";
import { getDtoFromWidgetConfig } from "../../../../utils/ExploreUtils";
import { logger } from "../../../../core";

const fetchEntityLookup = async (entityIds: string[]) => {
  const uniqEntityIds = new Set(entityIds.map(x => x.replace(/['"]+/g, "")));
  const entityLookUp: Record<string, string> = {};

  if (uniqEntityIds.size) {
    const entityPropertiesMap = await fetchEntityPropertiesForIds(uniqEntityIds, 0, Date.now());

    entityPropertiesMap.forEach((value, key) => {
      entityLookUp[key] = value.name;
    });
  }

  return entityLookUp;
};

export const appendProjectionsToTheLabel = (label: string, projections?: PostAggProjection[]) => {
  if (projections?.includes("delta")) {
    return `Change in ${label}`;
  } else if (projections?.includes("deltaPercentage")) {
    return `Change percentage in ${label}`;
  }
  return label;
};

export const getLabelFromBizDataQuery = async (
  bizDataQuery: BizDataQuery,
  rollingFreq?: TimeObj,
  filtersPrefix = "with",
  skipFilters = false
) => {
  const { sliceSpec, buildingBlockConfig, labels, widgetConfig, id, idProps } = bizDataQuery;
  const { sliceSet, buildingBlockConfigId, metricId } = sliceSpec || {};

  const metricName = labels?.name || labels?.metricName || "";

  if (!metricName) {
    if (buildingBlockConfigId) {
      return buildingBlockConfig?.name || "";
    }

    let widgetConfigDto: WidgetConfigDTO;
    if (widgetConfig) {
      widgetConfigDto = getDtoFromWidgetConfig(widgetConfig);
    }

    if (id) {
      try {
        const { entityTypeId, eventTypeId } = WidgetConfigUtils.getEntityTypeAndEventTypeFromIdProps(idProps);
        const { data, error, message } = await exploreApiService.getWidgetConfig(eventTypeId, entityTypeId, id);
        if (error) {
          logger.error("getLabelFromBizDataQuery", "Error getting label from bizDataQuery", message);
        } else {
          widgetConfigDto = data.widgetConfig;
        }
      } catch (err) {
        logger.error("getLabelFromBizDataQuery", "Error getting label from bizDataQuery", err);
      }
    }

    const metric = widgetConfigDto ? WidgetConfigUtils.getMetricDefinition(widgetConfigDto, metricId) : null;
    return metric?.name || "";
  }

  const { buildingBlockDef } = buildingBlockConfig || {};
  const { filters, fieldConfig } = buildingBlockDef || {};

  const filtersStrArr: string[] = [];
  if (filters && !skipFilters) {
    const { bizFieldPredicates = [], filterExpressions = [] } = filters;

    bizFieldPredicates.forEach(bizFieldPredicate => {
      const { label } = FieldPickerUtils.getBizFieldPredicatePillLabelAndInfo(bizFieldPredicate);
      filtersStrArr.push(label);
    });

    const entityIds = WidgetConfigUtils.getEntityIdsFromFilterExpressions(filterExpressions);
    const entityLookup = await fetchEntityLookup(entityIds);

    const fieldName = fieldConfig?.userServiceField?.fieldName;
    const isEventIDField = USFieldWidgetUtils.isEventIDField(fieldName);
    const fFilterExpressions = isEventIDField
      ? filterExpressions.filter(f => !USFieldWidgetUtils.isHasErrorField(f.field.fieldName))
      : filterExpressions;

    fFilterExpressions.forEach(filterExpression => {
      const label = WidgetConfigUtils.getUsFilterExpressionLabel(filterExpression, entityLookup);
      filtersStrArr.push(label);
    });
  }

  const filtersStr = filtersStrArr.length ? `${filtersPrefix} ${filtersStrArr.join(" and ")}` : "";

  const rollingFreqStr = rollingFreq ? getLabelForTimeObj(rollingFreq, "md", true) : "";
  const lookBackStr = rollingFreqStr ? `in the past ${rollingFreqStr}` : "";

  // Construct name from slices only in field operationalize case, since for metric case, name already has slices
  const slicesStrArr =
    buildingBlockConfigId && sliceSet
      ? sliceSet.slices.map(slice => {
          const { tagName, entityTypeName } = slice;

          return tagName === ENTITY_TAG ? entityTypeName : tagName;
        })
      : [];
  const slicesStr = slicesStrArr.length ? `by ${slicesStrArr.join(", ")}` : "";

  return [metricName, slicesStr, filtersStr, lookBackStr].filter(Boolean).join(" ");
};

export type PresetQueryData = {
  comparator: CompareOperator;
  aggregator?: string;
};
export type PresetQueryOption = IncSelectOption<PresetQueryData>;

/**
 * getPopulatedPresetOptions for widgetconfig and buildingBlockConfig
 * @param eventTypeName
 * @param isHasErrorField
 * @param isEventIDField
 * @returns
 */
const getPopulatedPresetOptions = (
  eventTypeName: string,
  userServiceField: UserServiceField,
  filterExpressions: any
) => {
  const presetOptions: PresetQueryOption[] = [];
  const fieldName = userServiceField?.fieldName;
  const isEventIDField = USFieldWidgetUtils.isEventIDField(fieldName);
  const isHasErrorField =
    isEventIDField && filterExpressions?.find((f: any) => USFieldWidgetUtils.isHasErrorField(f.field.fieldName));

  if (isHasErrorField) {
    const metricName = `Failed ${pluralizeWord(eventTypeName)}`;
    presetOptions.push(getPresetOption(metricName, CompareOperator.Above, "count"));
    presetOptions.push(getPresetOption(metricName, CompareOperator.Below, "count"));
    presetOptions.push(getPresetOption(metricName, CompareOperator.AboveOrBelow, "count"));
  } else if (isEventIDField) {
    const metricName = `Total ${pluralizeWord(eventTypeName)}`;
    presetOptions.push(getPresetOption(metricName, CompareOperator.Above, "count"));
    presetOptions.push(getPresetOption(metricName, CompareOperator.Below, "count"));
    presetOptions.push(getPresetOption(metricName, CompareOperator.AboveOrBelow, "count"));
    presetOptions.push(getPresetOption(eventTypeName, null));
  } else {
    presetOptions.push(getPresetOption("", CompareOperator.Above));
    presetOptions.push(getPresetOption("", CompareOperator.Below));
    presetOptions.push(getPresetOption("", CompareOperator.AboveOrBelow));
  }
  return presetOptions;
};

/**
 *getSelectedPopulatedPresetOption for widgetconfig and buildingBlockConfig
 * @param eventTypeName
 * @param isHasErrorField
 * @param isEventIDField
 * @param userServiceField
 * @param comparator
 * @returns
 */
const getSelectedPopulatedPresetOption = (
  eventTypeName: string,
  userServiceField: UserServiceField,
  filterExpressions: any,
  comparator: CompareOperator
) => {
  let matchOpt: PresetQueryOption;
  const fieldName = userServiceField?.fieldName;
  const isEventIDField = USFieldWidgetUtils.isEventIDField(fieldName);
  const isHasErrorField =
    isEventIDField && filterExpressions?.find((f: any) => USFieldWidgetUtils.isHasErrorField(f.field.fieldName));

  if (isHasErrorField) {
    const metricName = `Failed ${pluralizeWord(eventTypeName)}`;
    matchOpt =
      !comparator || comparator === CompareOperator.None
        ? getPresetOption(eventTypeName, null)
        : getPresetOption(metricName, comparator, "count");
  } else if (isEventIDField) {
    const metricName = `Total ${pluralizeWord(eventTypeName)}`;
    matchOpt =
      !comparator || comparator === CompareOperator.None
        ? getPresetOption(eventTypeName, null)
        : getPresetOption(metricName, comparator, "count");
  } else {
    const displayFieldName = USFieldWidgetUtils.getDisplayFieldName(userServiceField, eventTypeName);
    matchOpt = !comparator ? getPresetOption(displayFieldName, null) : getPresetOption("", comparator);
  }
  return matchOpt;
};

export const getPresetQueryOptions = (bizDataQuery: BizDataQuery, eventTypeName: string, isTypeTrend = false) => {
  let presetOptions: PresetQueryOption[] = [];

  if (bizDataQuery) {
    const { labels, id, buildingBlockConfig, widgetConfig, sliceSpec } = bizDataQuery;
    const metricName = labels?.name || labels?.metricName || "";
    // Metric Operationalize case
    if (id) {
      presetOptions.push(getPresetOption(metricName, CompareOperator.Above, null, isTypeTrend));
      presetOptions.push(getPresetOption(metricName, CompareOperator.Below, null, isTypeTrend));
      if (!isTypeTrend) {
        presetOptions.push(getPresetOption(metricName, CompareOperator.AboveOrBelow));
      }
    } else if (buildingBlockConfig) {
      const { buildingBlockDef } = buildingBlockConfig;
      const { fieldConfig, filters } = buildingBlockDef || {};
      const { filterExpressions } = filters || {};
      const { userServiceField } = fieldConfig || {};
      presetOptions = getPopulatedPresetOptions(eventTypeName, userServiceField, filterExpressions);
    } else if (widgetConfig) {
      const { dataDefinition } = widgetConfig;
      const { metrics } = dataDefinition || {};
      const metricId = sliceSpec?.metricId || "";
      const metric = metrics && metricId ? metrics[metricId] : null;
      if (metric) {
        const { name } = (metric as UserServiceFieldMetricConfigDefinition) || {};
        presetOptions.push(getPresetOption(name, CompareOperator.Above));
        presetOptions.push(getPresetOption(name, CompareOperator.Below));
        presetOptions.push(getPresetOption(name, CompareOperator.AboveOrBelow));
      }
    }
  }
  return presetOptions;
};

export const getSelectedPresetOption = (
  bizDataQuery: BizDataQuery,
  eventTypeName: string,
  comparator: CompareOperator,
  presetOptions: PresetQueryOption[]
) => {
  let matchOpt: PresetQueryOption;

  if (bizDataQuery) {
    const { labels, id, buildingBlockConfig, widgetConfig, sliceSpec } = bizDataQuery;
    const metricName = labels?.name || labels?.metricName || "";

    // Metric Operationalize case
    if (id) {
      matchOpt = getPresetOption(metricName, comparator);
    } else if (buildingBlockConfig) {
      const { buildingBlockDef } = buildingBlockConfig;
      const { fieldConfig, filters } = buildingBlockDef || {};
      const { filterExpressions } = filters || {};
      const { userServiceField } = fieldConfig || {};
      matchOpt = getSelectedPopulatedPresetOption(eventTypeName, userServiceField, filterExpressions, comparator);
    } else if (widgetConfig) {
      const { dataDefinition } = widgetConfig;
      const { metrics } = dataDefinition || {};
      const metricId = sliceSpec?.metricId || "";
      const metric = metrics && metricId ? metrics[metricId] : null;
      if (metric) {
        const { name } = (metric as UserServiceFieldMetricConfigDefinition) || {};
        matchOpt = getPresetOption(name, comparator);
      }
    }
  }

  return presetOptions.find(opt => opt.value === matchOpt?.value);
};

const getPresetOption = (
  metricOrFieldName: string,
  comparator: CompareOperator,
  aggregator?: string,
  isTypeTrend = false
): PresetQueryOption => {
  const prefix =
    comparator === CompareOperator.Above
      ? isTypeTrend
        ? "Upward trend"
        : "Spike"
      : comparator === CompareOperator.Below
        ? isTypeTrend
          ? "Downward trend"
          : "Drop"
        : comparator === CompareOperator.AboveOrBelow
          ? "Spike or Drop"
          : "Any";

  const delimiter = prefix === "Any" ? " " : " in ";
  const label = [prefix, metricOrFieldName].filter(Boolean).join(delimiter);

  return {
    label,
    value: label,
    data: {
      comparator,
      aggregator
    }
  };
};
