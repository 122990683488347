export enum FEATURE_FLAGS {
  enableDebugMode = "Enable Debug Mode",
  enableDemoMode = "Enable Demo Mode",
  enableTriageEventsTimeline = "Show triage events in non-demo tenants also",
  enableLinkedDashboards = "Enable linked dashboards",
  dryRunOperationalise = "Dry run operationalise v2",
  dryRunAddMetric = "Dry run add metric",
  allowSaveInAddMetric = "Allow save (overwrite) widget in add metric",
  enableViewModeSwitchInBizCatalog = "Enable view mode switch in business catalog widgets",
  enableAddWidget = "Enable add widget",
  enableJourneys = "Enable Journeys in Business catalog",
  enableChat = "Enable chat screen",
  useV1ApiForChat = "Use V1 API for chat",
  useAgentContextForMessagesAndUseCaseHome = "Use AGENT_CHAT as conversation context for messages",
  enableCohortsInWidgetConfigs = "Enable cohorts in widget configs",
  enableSuppressionBands = "Enable Suppression bands in op10ze widgets",
  enableJourneysRoute = "Enable Journeys route",
  enableSavedWidgetQueryInDataTable = "Enable save widget in data table widget",
  useNewDrillDownWidget = "Enable new triage drill down widget",
  useGPT4ForOnboarding = "Use GPT-4 strategy for onboarding",
  dryRunOnboardingDemoData = "Dry run onboarding demo data generation",
  enableVirtualFields = "Enable Virtual Fields",
  useComplexExpressions = "Enable complex filter expressions",
  showHeatmap = "Show Alerts HeatMap in operationalize",
  playGround = "Show Operationalize Playground",
  enableTimezone = "Enable Timezone",
  enableCompanyNameInCSVConnector = "Enable Company Name in csv connector",
  showNewWidgetsInBusinessCatalog = "Show new widgets in Business Catalog",
  showHiddenUseCases = "Show Hidden Usecases",
  enableNewRecipeInOperationalize = "Enable new recipe UI for operationalize",
  replaceApiProxy = "Enable Apptuit(Grafana) proxy instead of using api-proxy",
  showInconsistencyModalForCatalogWidget = "Show inconsistency modal for catalog widget when UI metrics do not match widgetConfig's metrics",
  showKpisInAddMetric = "Show KPIs in Add Widget"
}

// Customisation components
export const featureFlagCustomistions: Record<string, JSX.Element> = {};
