import { zipObject } from "lodash";
import { CompareQuerySchemaResponse, TimeObj } from "../../services/api/explore";
import { shouldExcludeTag } from "../../core";
import { MonitoredDataSchema, OpThresholdSeasonality } from "../../services/api/operationalise";

export const getSchemaCombinations = (schemaResponse: CompareQuerySchemaResponse) => {
  const validCombinations = new Set<string>();
  schemaResponse?.resultSchema?.forEach(schema => {
    let combinationStr = "";

    const sortedKeys = Object.keys(schema)
      .filter(tag => !shouldExcludeTag(tag, true))
      .sort();

    sortedKeys.forEach(key => {
      const value = schema[key];
      combinationStr += `${key}:${value}`;
    });
    validCombinations.add(combinationStr);
  });
  return validCombinations;
};

export const getSchemaCombinationsV2 = (schemaResponse: MonitoredDataSchema) => {
  const combinationVsFreq: Record<string, TimeObj> = {};
  const combinationVsSeasonality: Record<string, OpThresholdSeasonality> = {};
  const combinationVsLookBack: Record<string, TimeObj> = {};

  schemaResponse?.series?.forEach(serie => {
    const tagKeys: string[] = [];
    const tagValues: string[] = [];
    const { timeSeries, freq } = serie;

    timeSeries.label.forEach(tag => {
      tagKeys.push(tag.name);
      tagValues.push(tag.value);
    });

    const tags = zipObject(tagKeys, tagValues);
    const combinationStr = getCombinationStrForTags(tags);

    combinationVsFreq[combinationStr] = freq?.frequency;
    combinationVsSeasonality[combinationStr] = freq?.seasonality;
    combinationVsLookBack[combinationStr] = freq?.lookBack;
  });

  return {
    combinationVsFreq,
    combinationVsLookBack,
    combinationVsSeasonality
  };
};

export const getCombinationStrForTags = (tags: Record<string, string>) => {
  let combinationStr = "";
  const sortedKeys = Object.keys(tags)
    .filter(tag => !shouldExcludeTag(tag, true))
    .sort();

  sortedKeys.forEach(key => {
    const value = tags[key];
    combinationStr += `${key}:${value}`;
  });

  return combinationStr;
};
