import React, { FC, useMemo, useRef, isValidElement, useState, useCallback, useEffect } from "react";
import { CellProps, Column, Row, SortByFn, UseSortByColumnOptions, FilterProps } from "react-table";
import { omit, sortBy, pick, debounce } from "lodash";
import { TableDataColumn, TableDataItem, ExtColumn, FilterRendererProps } from "../types";
import { IncRTableProps } from "../props";
import { IncFaIcon, IncFaIconName } from "../../Icons";
import { generateId } from "../../../utils";
import { IncClickAwayPopper } from "../../ClickAway/IncClickAwayPopper";
import IncTextfield from "../../Textfield/TextField";
import IncSmartText from "../../SmartText/SmartText";
import { IncCheckbox } from "../../antd-components";
import useCellRenderer from "./useCellRenderer";
import { getCircularJSONString } from "./utils";

type ColGenProps<T extends TableDataItem> = {
  paginationEnabled: boolean;
  enableSelection: boolean;
  columns: IncRTableProps<T>["columns"];
  nonDataColumns: IncRTableProps<T>["nonDataColumns"];
  subRowsExist: boolean;
  alwaysExpanded: boolean;
  tableWidth: number;
  enableAllRowSelection: boolean;
  expandIconVariant: IncRTableProps["expandIconVariant"];
  expansionColumnWidth?: number;
  skipForceShowSubRows?: boolean;
};

const useConstructColumns = <T extends TableDataItem>(props: ColGenProps<T>) => {
  const {
    nonDataColumns,
    paginationEnabled,
    columns,
    enableSelection,
    subRowsExist,
    alwaysExpanded,
    tableWidth,
    enableAllRowSelection,
    expandIconVariant,
    expansionColumnWidth
  } = props;

  // Map of IDs vs columns. We use column's stringified JSON as key
  const columnsMap = useRef<Map<string, string>>(new Map());

  const selectionColumnId = useMemo(() => `selection-${generateId()}`, []);
  const expansionColumnId = useMemo(() => `expansion-${generateId()}`, []);
  //calculate the expansion column based on the aggregatedColumns

  const selectionColumn: Column<T> = useMemo(
    () => ({
      id: selectionColumnId,
      Header: ({ getToggleAllPageRowsSelectedProps, getToggleAllRowsSelectedProps }) =>
        paginationEnabled && !enableAllRowSelection ? (
          <IncCheckbox {...getToggleAllPageRowsSelectedProps()} />
        ) : (
          <IncCheckbox {...getToggleAllRowsSelectedProps()} />
        ),
      Cell: ({ row }: CellProps<T>) => <IncCheckbox {...row.getToggleRowSelectedProps()} />,
      Footer: () => <div className="inc-table-cell-loading" />,
      width: 50,
      maxWidth: 50,
      defaultCanFilter: false,
      disableFilters: true
    }),
    [paginationEnabled, enableAllRowSelection, selectionColumnId]
  );

  const expansionColumn: Column<T> = useMemo(
    () => ({
      id: expansionColumnId,
      Header: <></>,
      Cell: ({ row }: CellProps<T>) => {
        const { isExpanded, canExpand, getToggleRowExpandedProps } = row;

        const downIcon: IncFaIconName = expandIconVariant === "circle" ? "chevron-circle-down" : "chevron-down";
        const upIcon: IncFaIconName = expandIconVariant === "circle" ? "chevron-circle-right" : "chevron-right";
        const icon = isExpanded ? downIcon : upIcon;

        return canExpand ? (
          <IncFaIcon
            {...getToggleRowExpandedProps({ className: "inc-cursor-pointer" })}
            iconName={icon}
          />
        ) : (
          <></>
        );
      },
      Footer: () => <div className="inc-table-cell-loading" />,
      className: "expansion-cell",
      width: expansionColumnWidth || 40,
      maxWidth: expansionColumnWidth || 40,
      defaultCanFilter: false,
      disableFilters: true
    }),
    [expandIconVariant, expansionColumnId, expansionColumnWidth]
  );

  const { dataColumns, columnVsGlobalFilterFn } = useMemo(() => {
    const dCols: Array<ExtColumn<T>> = [];
    const colVsGFilterFn: Record<string, TableDataColumn<T>["globalFilterFn"]> = {};

    columns.forEach((col, idx) => {
      const getSortFn = (
        sortFn: TableDataColumn<T>["sortFn"],
        type: TableDataColumn<T>["type"]
      ): UseSortByColumnOptions<T>["sortType"] => {
        const uSortFn: SortByFn<T> | undefined = sortFn
          ? (row1, row2, columnId, desc) => {
              const colId = parseColumnIdForAccessor(columnId);
              const row1IsSubRow = row1.depth > 0;
              const row2IsSubRow = row2.depth > 0;
              const res = sortFn(row1.original, row2.original, colId, desc, row1IsSubRow, row2IsSubRow);
              return res === "equal" ? 0 : res === "lesser" ? -1 : 1;
            }
          : undefined;

        let fSortFn: UseSortByColumnOptions<T>["sortType"] = uSortFn;

        if (type === "custom" || type === "tags") {
          fSortFn = fSortFn || "basic";
        } else if (type === "relativedatetime" || type === "datetime") {
          fSortFn =
            fSortFn || ((row1, row2, columnId) => DateTimeCompareFn(type === "relativedatetime", row1, row2, columnId));
        } else {
          fSortFn = fSortFn || type;
        }

        return fSortFn;
      };

      const {
        accessor,
        type = "string",
        defaultSort = "asc",
        renderer,
        sortFn,
        sortable = false,
        header,
        index = idx,
        hide = false,
        width,
        align = "left",
        disableTableEvents,
        className,
        headerClassName,
        filterable = false,
        filterRenderer,
        filterFn,
        globalFilterFn
      } = col;

      const widthNum = parseInt((width || 0).toString(), 10);
      const colId = getAndUpdateColumnId(col, columnsMap.current);

      if (!hide) {
        const column: ExtColumn<T> = {
          accessor,
          Cell: ({ cell }: CellProps<T>) => <Content align={align}>{useCellRenderer(cell, type, renderer)}</Content>,
          Footer: () => <div className="inc-table-cell-loading" />,
          Header: <Content align={align}>{header}</Content>,
          disableSortBy: !sortable,
          sortType: getSortFn(sortFn, type),
          sortDescFirst: defaultSort !== "asc",
          idx: index,
          id: colId,
          disableTableEvents,
          className,
          headerClassName,
          Filter: props =>
            filterable ? (
              <FilterRenderer
                {...props}
                customRenderer={filterRenderer}
              />
            ) : (
              <></>
            ),
          disableFilters: !filterable,
          filter: colId,
          filterFn,
          defaultCanFilter: filterable
        };

        if (width) {
          const widthInPer = width.toString().endsWith("%");
          const fWidth = widthInPer ? Math.floor((widthNum / 100) * tableWidth) : widthNum;
          column.width = fWidth;
          column.maxWidth = fWidth;
          column.disableResizing = true;
        }

        dCols.push(column);
        colVsGFilterFn[colId] = globalFilterFn;
      }
    });

    return {
      dataColumns: dCols,
      columnVsGlobalFilterFn: colVsGFilterFn
    };
  }, [columns, tableWidth]);

  const numDataCols = dataColumns.length;

  const [actColumns, nonDataColumnIds] = useMemo(() => {
    const aColIds: string[] = [];
    const aCols = (nonDataColumns || []).map((aColumn, idx): ExtColumn<T> => {
      const aColId = getAndUpdateColumnId(aColumn as any, columnsMap.current);
      aColIds.push(aColId);

      const {
        align = "left",
        header,
        renderer,
        index = numDataCols + idx, // Push the actions to last
        width
      } = aColumn;

      const widthNum = parseInt((width || 0).toString(), 10);

      const aCol: ExtColumn<T> = {
        idx: index,
        Header: <Content align={align}>{header}</Content>,
        Cell: ({ row }: CellProps<T>) => <Content align={align}>{renderer(row.original)}</Content>,
        Footer: () => <div className="inc-table-cell-loading" />,
        id: aColId,
        minWidth: widthNum > 0 ? widthNum : 100,
        defaultCanFilter: false,
        disableFilters: true
      };

      if (width) {
        const widthInPer = width.toString().endsWith("%");
        const fWidth = widthInPer ? Math.floor((widthNum / 100) * tableWidth) : widthNum;
        aCol.width = fWidth;
        aCol.maxWidth = fWidth;
        aCol.disableResizing = true;
      }
      return aCol;
    });

    return [aCols, aColIds];
  }, [nonDataColumns, numDataCols, tableWidth]);

  const tableColumns = useMemo(() => {
    const unorderedColumns = [...dataColumns, ...actColumns];
    const orderedColumns = sortBy(unorderedColumns, col => col.idx);
    let tCols = orderedColumns.map(colWithIdx => omit(colWithIdx, ["idx"]) as any as Column<T>);
    tCols =
      enableSelection && subRowsExist && !alwaysExpanded
        ? [selectionColumn, expansionColumn, ...tCols]
        : enableSelection
          ? [selectionColumn, ...tCols]
          : subRowsExist && !alwaysExpanded
            ? [expansionColumn, ...tCols]
            : tCols;

    const countMap = new Map<string, number>();
    tCols.forEach(col => {
      const id = col.id as string;
      const count = countMap.get(id) || 0;
      if (count > 0) {
        col.id = `${id}-${count}`;
      }
      countMap.set(id, count + 1);
    });
    return tableWidth ? tCols : [];
  }, [
    actColumns,
    alwaysExpanded,
    dataColumns,
    enableSelection,
    expansionColumn,
    selectionColumn,
    subRowsExist,
    tableWidth
  ]);

  return {
    tableColumns,
    columnVsGlobalFilterFn,
    selectionColumnId,
    nonDataColumnIds,
    expansionColumnId
  };
};

export const parseColumnIdForAccessor = (id: string): string => {
  const idArr = id.split("_");
  //const idx = parseInt(last(idArr) as string, 10);
  const accessor = idArr.slice(0, -1).join("_");
  return accessor;
};

export default useConstructColumns;

const DateTimeCompareFn = <T extends TableDataItem>(
  relative: boolean,
  row1: Row<T>,
  row2: Row<T>,
  columnId: string
) => {
  const getDate = (d: string | number | Date) => {
    let date: Date;
    if (typeof d === "string" || typeof d === "number") {
      date = new Date(d);
    } else {
      date = d;
    }
    return date;
  };

  const colId = parseColumnIdForAccessor(columnId);

  const a = (row1.original as any)[colId];
  const b = (row2.original as any)[colId];

  const dateA = getDate(a);
  const dateB = getDate(b);

  const timeA = dateA?.getTime();
  const timeB = dateB?.getTime();

  return timeA > timeB ? (relative ? -1 : 1) : relative ? 1 : -1;
};

type Props = {
  align: "right" | "center" | "left";
};

const Content: FC<Props> = props => (
  <div className={`table-content-align-${props.align}`}>
    {typeof props.children === "string" ? <IncSmartText text={props.children} /> : props.children}
  </div>
);

const constructColumnId = (accessor: string) => `${accessor}_${generateId()}`;

const getAndUpdateColumnId = <T extends TableDataItem>(
  column: TableDataColumn<T>,
  columnIdMap: Map<string, string>
): string => {
  const keys = Object.keys(column).filter(k => {
    const key = k as keyof TableDataColumn<T>;
    const value = column[key];
    return !isValidElement(value);
  });

  const colObj = pick(column, keys);
  const colJson = getCircularJSONString(colObj);
  const colId = columnIdMap.get(colJson) || constructColumnId((column.accessor || "action") as string);
  columnIdMap.set(colJson, colId);
  return colId;
};

type FProps<T extends TableDataItem> = FilterProps<T> & {
  customRenderer?: (rProps: FilterRendererProps, tableData: T[]) => JSX.Element;
};

const FilterRenderer = <T extends TableDataItem>(props: FProps<T>) => {
  const { state, setFilter, customRenderer: renderer, column, rows } = props;

  const { Header } = column;

  const filter = state.filters.find(fil => fil.id === column.id)?.value || "";

  const [filterStr, setFilterStr] = useState(filter);

  useEffect(() => {
    setFilterStr(filter);
  }, [filter]);

  const updateFilter = useCallback((value: any) => setFilter(column.id, value), [column.id, setFilter]);
  const debouncedUpdateFilter = useMemo(() => debounce((value: any) => updateFilter(value), 500), [updateFilter]);

  const updateLocalFilter = useCallback(
    (value: any) => {
      setFilterStr(value);
      debouncedUpdateFilter(value);
    },
    [debouncedUpdateFilter]
  );

  const ref = useRef<HTMLDivElement>(null);
  const [open, setOpen] = useState(false);

  const openPopper = useCallback((e: React.MouseEvent<HTMLDivElement>) => {
    e.stopPropagation();
    setOpen(true);
  }, []);

  const closePopper = useCallback(() => setOpen(false), []);

  const tableData = rows.map(r => r.original);

  const filterComponent = useMemo(() => {
    if (renderer) {
      return renderer(
        {
          filter,
          open,
          setFilter: updateFilter
        },
        tableData
      );
    }

    if (typeof filter === "string" || typeof filter === "number") {
      const onChange = (e: any) => {
        updateLocalFilter(e.target.value);
      };

      const onClick = (e: any) => {
        e.stopPropagation();
      };

      return (
        <IncTextfield
          autoFocus
          containerClassName="inc-table--filter-field"
          label={Header as string}
          onChange={onChange}
          onClick={onClick}
          value={filterStr}
        />
      );
    }

    return <div>Cannot handle this filter</div>;
  }, [Header, filter, filterStr, open, renderer, tableData, updateFilter, updateLocalFilter]);

  return (
    <>
      <div
        className="marginLt6 inc-table--filter-icon inc-cursor-pointer"
        onClick={openPopper}
        ref={ref}
        title="Filter"
      >
        <IncFaIcon iconName="filter" />
        {Boolean(filter) && <div className="selection-dot" />}
      </div>

      <IncClickAwayPopper
        anchorEl={ref.current as HTMLElement}
        className="inc-table--filter-popover"
        onClickAway={closePopper}
        show={open}
      >
        {filterComponent}
      </IncClickAwayPopper>
    </>
  );
};
