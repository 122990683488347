import React, { ComponentPropsWithRef, CSSProperties, FC, forwardRef, useMemo } from "react";
import { cx } from "emotion";
import { getInceptionTheme } from "../../themes/ThemeProvider";
import { IncInfoIcon, IncErrorIcon, IncFaIconName } from "../Icons";
import { getStringPossibleWidth } from "../../utils";
import { IncGenericIcon, ISaxIconProps } from "../../icons";
import { IncToolTip } from "../antd-components";

export interface IncTextfieldProps extends React.HTMLAttributes<HTMLElement>, ComponentPropsWithRef<any> {
  label?: string;
  labelClass?: string;

  hasError?: boolean;
  errorText?: string; // pass error text to be shown on tooltip

  readOnly?: boolean;

  helpText?: string; // pass content to show on tooltip
  helpTextId?: string; // pass i18n id to show on tooltip
  containerClassName?: string;
  required?: boolean;
  autoAdjustWidth?: boolean;

  startIcon?: IncFaIconName | ISaxIconProps["iconName"];
  endIcon?: IncFaIconName | ISaxIconProps["iconName"];
  onEndIconClick?: () => void;
  endIconClassName?: string;
}

const IncTextfield: FC<IncTextfieldProps> = forwardRef<HTMLInputElement, IncTextfieldProps>((props, ref) => {
  const {
    label,
    labelClass = "",
    disabled = false,
    readOnly = false,
    className = "",
    value,
    hasError,
    errorText,
    helpTextId,
    helpText,
    containerClassName = "",
    required,
    startIcon,
    endIcon,
    onEndIconClick,
    endIconClassName = "",
    autoAdjustWidth = false,
    placeholder,
    ...otherProps
  } = props;

  const appliedTextInputClassName = cx(className, "input-text-field", {
    "inc-text-error": hasError,
    "input-text-field--has-start-icon": Boolean(startIcon),
    "input-text-field--has-end-icon": Boolean(endIcon)
  });

  const inputStyle = useMemo<CSSProperties>(() => {
    if (autoAdjustWidth) {
      const buffer = otherProps.type === "number" ? 48 : 32;
      const textWidth = getStringPossibleWidth(String(value || placeholder), 12, undefined, 500).width;
      return {
        width: `${textWidth + buffer}px`
      };
    }

    return {};
  }, [autoAdjustWidth, otherProps.type, placeholder, value]);

  const input = (
    <input
      autoComplete="off"
      className={appliedTextInputClassName}
      disabled={disabled}
      readOnly={readOnly}
      ref={ref}
      value={value}
      {...otherProps}
      placeholder={placeholder}
      style={inputStyle}
    />
  );
  const appliedContainerClassName = `textfield-container ${containerClassName}`;

  const errComponent =
    errorText && hasError ? (
      <IncToolTip
        placement="top-start"
        showArrow
        titleText={errorText}
        variant="error"
      >
        <IncErrorIcon
          style={{
            color: getInceptionTheme().inceptionColors.palette.R400,
            marginLeft: !label ? 8 : 0
          }}
        />
      </IncToolTip>
    ) : (
      <></>
    );

  return (
    <div
      className={appliedContainerClassName}
      data-show-loader={props["data-show-loader"]}
    >
      {label && (
        <div className="input-label">
          <span className={labelClass}>{label}</span>
          {required && <span className="inc-span-required-star">*</span>}
          {(helpTextId || helpText) && (
            <IncToolTip
              placement="top"
              showArrow
              titleId={helpTextId}
              titleText={helpText}
            >
              <IncInfoIcon />
            </IncToolTip>
          )}
          {errComponent}
        </div>
      )}
      <div className="inc-flex-row inc-flex-vertical-center">
        {startIcon && (
          <IncGenericIcon
            className="start-icon"
            iconName={startIcon}
            size={14}
          />
        )}
        {input}
        {endIcon && (
          <IncGenericIcon
            className={cx("end-icon", endIconClassName)}
            iconName={endIcon}
            onClick={onEndIconClick ? onEndIconClick : () => {}}
            size={14}
          />
        )}
        {!label && errComponent}
      </div>
    </div>
  );
});

export default IncTextfield;
