import { useEffect, useState, useCallback, RefObject } from "react";

interface Props {
  ref: RefObject<HTMLElement>;
}

/**
 * @description
 *  useFullScreen hook enables any HTMLElement to be shown in a full screen
 *  it only requires the reference of the HTMLElement as a prop
 *
 * @returns
 * - isFullscreenEnabled is a boolean variable which is true when full screen is enabled and false when it is disabled
 * - toggleFullScreen as name suggests this function allows the parent component to toggle full screen view
 */
const useFullScreen = (props: Props) => {
  const [isFullscreenEnabled, setFullScreen] = useState(false);
  const { ref } = props;

  useEffect(() => {
    const fullScreenEventListener = () => {
      setFullScreen(fullscreen => !fullscreen);
    };
    document.addEventListener("fullscreenchange", fullScreenEventListener);

    return () => document.removeEventListener("fullscreenchange", fullScreenEventListener);
  }, []);

  const toggleFullScreen = useCallback(() => {
    if (ref.current && !getFullScreenElement()) {
      ref.current.requestFullscreen();
    } else {
      document.exitFullscreen();
    }
  }, [ref]);

  return {
    isFullscreenEnabled,
    toggleFullScreen
  };
};

export { useFullScreen };

const getFullScreenElement = () =>
  document.fullscreenElement ||
  (document as any).webkitFullscreenElement ||
  (document as any).mosFullscreenElement ||
  (document as any).msFullscreenElement ||
  null;
