import { round } from "lodash";
import { duration } from "moment";

export type DurationPrecision = "DAYS" | "HOURS" | "MINUTES" | "SECONDS";

export const convertTimeToHumanString = (
  timeInMicros: number,
  fixed = 0,
  maxTerms?: number,
  precision?: DurationPrecision
) => {
  if (timeInMicros < 1000) {
    return `${parseFloat(`${timeInMicros}`).toFixed(fixed)}µs`;
  }

  const timeInMillis = timeInMicros / 1000;
  const roundedMillis = round(parseFloat(`${timeInMillis}`), fixed);

  const durationMillisObj = duration(roundedMillis, "ms");
  const msDuration = durationMillisObj.milliseconds();
  const secsDuration = durationMillisObj.seconds();
  const minsDuration = durationMillisObj.minutes();
  const hoursDuration = durationMillisObj.hours();
  const daysDuration = durationMillisObj.days();
  const monthsDuration = durationMillisObj.months();
  const yearsDuration = durationMillisObj.years();

  let humanString = "";
  humanString += yearsDuration ? `${yearsDuration}y ` : "";
  humanString += monthsDuration ? `${monthsDuration}M ` : "";
  humanString += daysDuration ? `${daysDuration}d ` : "";
  humanString += hoursDuration ? `${hoursDuration}h ` : "";
  humanString += minsDuration ? `${minsDuration}m ` : "";
  humanString += secsDuration ? `${secsDuration}s ` : "";
  humanString += msDuration ? `${msDuration}ms ` : "";
  humanString = humanString.trim();

  if (precision && maxTerms) {
    const precisionLevels: Record<string, number> = {
      y: 0,
      M: 1,
      d: 2,
      h: 3,
      m: 4,
      s: 5,
      ms: 6,
      µs: 7
    };

    const precisionMap: Record<string, number> = {
      DAYS: precisionLevels["d"],
      HOURS: precisionLevels["h"],
      MINUTES: precisionLevels["m"],
      SECONDS: precisionLevels["s"]
    };

    const maxPrecisionLevel = precision ? precisionMap[precision] : 7;
    const parts = humanString.split(/\s+/);

    const selectedParts = parts
      .filter(part => precisionLevels[part.replace(/[0-9.]/g, "")] <= maxPrecisionLevel)
      .slice(0, maxTerms);

    const defaultPrecisionLabels: Record<string, string> = {
      DAYS: "0d",
      HOURS: "0h",
      MINUTES: "0m",
      SECONDS: "0s"
    };

    humanString = selectedParts.length > 0 ? selectedParts.join(" ") : `${defaultPrecisionLabels[precision]}`;
  }

  return humanString;
};
