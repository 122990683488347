import React, { useState, useCallback } from "react";
import ReactDatePicker from "react-datepicker";
import { IncInfoIcon } from "../Icons";
import { IncDateRangeProps } from "../DateTimePicker/types";
import { IncToolTip } from "../antd-components";

const classes = {
  input: "inc-date-picker--input",
  calendar: "inc-date-picker--calendar",
  popper: "inc-date-picker--popper"
};

const DateRangePicker: React.FC<IncDateRangeProps> = (props: IncDateRangeProps) => {
  const {
    label,
    containerClass,
    calendarClassName: usrCalendar = "",
    className: usrInput = "",
    popperClassName: usrPopper = "",
    from,
    to,
    onChange: usrOnChange = () => {},
    helpText = "",
    monthsShown = 2,
    ...dateRangeProps
  } = props;

  const [value, setValue] = useState<[Date | null, Date | null]>([from, to]);

  const onChange = useCallback(
    (range: [Date | null, Date | null]) => {
      const startDate = range[0];
      const endDate = range[1];
      setValue([startDate, endDate]);
      usrOnChange(startDate, endDate);
    },
    [usrOnChange]
  );

  const className = `${classes.input} ${usrInput}`;
  const calendarClassName = `${classes.calendar} ${usrCalendar}`;
  const popperClassName = `${classes.popper} ${usrPopper}`;

  const [startDate, endDate] = value;

  const containerClassName = `inc-date-picker-container ${containerClass}`;

  return (
    <div className={containerClassName}>
      {Boolean(label) && (
        <label className="inc-date-picker--label">
          {helpText && (
            <IncToolTip
              placement="top-start"
              titleText={helpText}
            >
              <IncInfoIcon />
            </IncToolTip>
          )}
          {label}
        </label>
      )}
      <ReactDatePicker
        calendarClassName={calendarClassName}
        className={className}
        endDate={endDate}
        monthsShown={monthsShown}
        onChange={onChange}
        popperClassName={popperClassName}
        portalId="date-range-portal"
        selected={startDate}
        selectsRange={true}
        showPopperArrow={false}
        showTimeSelect={false}
        startDate={startDate}
        timeFormat="HH:mm"
        timeIntervals={15}
        {...dateRangeProps}
      />
    </div>
  );
};

export default DateRangePicker;
