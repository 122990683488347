import { debounce, range } from "lodash";
import { Moment } from "moment";
import React, { useMemo, useState, useEffect } from "react";
import ReactDatePicker from "react-datepicker";
import { IncInfoIcon } from "../Icons";
import { IncToolTip } from "../antd-components";
import { getDateTimeStringFormat, IncDateTimeFormat } from "../../utils";
import IncDateTimeProps from "./types";

const classes = {
  input: "inc-date-picker--input",
  calendar: "inc-date-picker--calendar",
  popper: "inc-date-picker--popper"
};

const IncDateTimePicker: React.FC<IncDateTimeProps> = props => {
  const {
    label,
    calendarClassName: usrCalendar = "",
    className: usrInput = "",
    popperClassName: usrPopper = "",
    value: pValue,
    onChange: usrOnChange = () => {},
    helpText = "",
    ...dateTimeProps
  } = props;

  const [value, setValue] = useState<Date>(pValue);

  useEffect(() => {
    setValue(prev => (prev?.valueOf() !== pValue.valueOf() ? pValue : prev));
  }, [pValue]);

  const usrChangeDebounce = useMemo(() => debounce((dateObj: Date) => usrOnChange(dateObj)), [usrOnChange]);

  const className = `${classes.input} ${usrInput}`;
  const calendarClassName = `${classes.calendar} ${usrCalendar}`;
  const popperClassName = `${classes.popper} ${usrPopper}`;

  const onChange = (newDate: string | Moment | Date) => {
    let dateObj: Date;
    if (newDate) {
      if (typeof newDate === "string") {
        dateObj = new Date(newDate);
      } else if (newDate instanceof Date) {
        dateObj = newDate;
      } else {
        dateObj = newDate.toDate();
      }

      setValue(dateObj);
      usrChangeDebounce(dateObj);
    }
  };

  const dateFormat = useMemo(() => getDateTimeStringFormat(IncDateTimeFormat.numeric, { withSeconds: true }), []);

  return (
    <div className="inc-date-picker-container">
      {Boolean(label) && (
        <label className="inc-date-picker--label">
          {helpText && (
            <IncToolTip
              placement="top-start"
              titleText={helpText}
            >
              <IncInfoIcon />
            </IncToolTip>
          )}
          {label}
        </label>
      )}
      <ReactDatePicker
        calendarClassName={calendarClassName}
        className={className}
        dateFormat={dateFormat}
        onChange={onChange}
        popperClassName={popperClassName}
        renderCustomHeader={renderCustomHeader}
        selected={value}
        showPopperArrow={false}
        showTimeSelect
        timeFormat="HH:mm"
        timeIntervals={15}
        {...(dateTimeProps as any)}
      />
    </div>
  );
};

export default IncDateTimePicker;

interface RenderCustomHeaderProps {
  date: Date;
  changeYear: (year: number | string) => void;
  changeMonth: (month: number | string) => void;
  decreaseMonth: () => void;
  increaseMonth: () => void;
  prevMonthButtonDisabled: boolean;
  nextMonthButtonDisabled: boolean;
}

export const renderCustomHeader: React.ReactNode = ({
  date,
  changeYear,
  changeMonth,
  decreaseMonth,
  increaseMonth,
  prevMonthButtonDisabled,
  nextMonthButtonDisabled
}: RenderCustomHeaderProps) => {
  const years = range(1990, new Date().getFullYear() + 1, 1);
  const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December"
  ];

  return (
    <div
      style={{
        margin: "0 15px 0 15px",
        display: "flex",
        justifyContent: "space-between"
      }}
    >
      <button
        className="react-datepicker__inc-datepicker-button"
        disabled={prevMonthButtonDisabled}
        onClick={decreaseMonth}
      >
        {"<"}
      </button>
      <select
        className="react-datepicker__inc-datepicker-select"
        onChange={({ target: { value } }) => changeMonth(months.indexOf(value))}
        value={months[date.getMonth()]}
      >
        {months.map(option => (
          <option
            key={option}
            value={option}
          >
            {option}
          </option>
        ))}
      </select>
      <select
        className="react-datepicker__inc-datepicker-select"
        onChange={({ target: { value } }) => changeYear(value)}
        value={date.getFullYear()}
      >
        {years.map(option => (
          <option
            key={option}
            value={option}
          >
            {option}
          </option>
        ))}
      </select>
      <button
        className="react-datepicker__inc-datepicker-button right"
        disabled={nextMonthButtonDisabled}
        onClick={increaseMonth}
      >
        {">"}
      </button>
    </div>
  );
};
