import { uniqBy } from "lodash";
import { useCallback, useMemo, useState } from "react";
import { useCaseApiService, UseCaseUnderlyingInfo } from "../../services/api";
import { BizEntityType, ResultEntity } from "../../services/api/explore";
import { logger } from "../logging/Logger";
import { useRefState } from "./useRefState";
import { useTenantConfig } from "./tenant-config";
import { VerticalUseCaseSummary, VerticalUseCaseUnderlyingInfo, useVerticalConfig } from "./vertical-config";

export const useFetchUseCaseUnderlyingInfo = () => {
  const { tenantConfigState } = useTenantConfig();
  const { useNewNavigation, demoTenant } = tenantConfigState || {};

  const { useCaseApi, useCaseSchemaApi, verticalConfig } = useVerticalConfig();
  const { useCaseToUnderlyingInfo } = verticalConfig;

  const underlyingInfoRef = useRefState(useCaseToUnderlyingInfo);

  const { getSelectedUseCases } = useCaseApi;
  const { updateUnderlyingInfo } = useCaseSchemaApi;

  const { useCaseIds, useCasesMap } = useMemo(() => {
    const useCases = getSelectedUseCases();
    return {
      useCaseIds: useCases.map(useCase => useCase.id),
      useCasesMap: useCases.reduce(
        (acc, useCase) => ({
          ...acc,
          [useCase.id]: useCase
        }),
        {} as Record<string, VerticalUseCaseSummary>
      )
    };
  }, [getSelectedUseCases]);

  const [state, setState] = useState<State>({
    isFetching: useNewNavigation,
    isSuccess: false,
    bizEntityTypes: [],
    entityTypeToEventTypeMap: {},
    eventIdToEntityTypeMap: {},
    eventTypes: []
  });

  const fetchUnderlyingInfo = useCallback(async () => {
    setState({
      isSuccess: false,
      isFetching: true,
      bizEntityTypes: [],
      entityTypeToEventTypeMap: {},
      eventIdToEntityTypeMap: {},
      eventTypes: []
    });

    const useCaseToUnderlyingInfo = underlyingInfoRef.current;
    const promises = useCaseIds.map(useCaseId => {
      const existingInfo = useCaseToUnderlyingInfo[useCaseId];
      if (existingInfo) {
        return Promise.resolve({
          data: existingInfo,
          error: false,
          message: ""
        });
      }

      if (demoTenant) {
        const data: VerticalUseCaseUnderlyingInfo = {
          bizEntityTypes: [],
          entityTypeToEventIds: {},
          eventIdToEntityTypes: {},
          eventTypes: (useCasesMap[useCaseId].involvedEventTypes || []).map(eventType => ({
            entityId: eventType,
            name: eventType
          }))
        };

        return Promise.resolve({
          data,
          error: false,
          message: ""
        });
      } else {
        return useCaseApiService.getUnderlyingInfoForUseCase(useCaseId).then(response => {
          const { data, ...restResponse } = response;

          return {
            data: getVerticalUseCaseUnderlyingInfo(data),
            ...restResponse
          };
        });
      }
    });

    const underlyingInfoMap: Record<string, VerticalUseCaseUnderlyingInfo> = {};

    const results = await Promise.allSettled(promises);
    results.forEach((result, idx) => {
      if (result.status === "fulfilled") {
        const { data, error, message } = result.value;

        if (error) {
          logger.error("useFetchUseCaseUnderlyingInfo", "Error while fetching Copilot underlying info", message);
        } else {
          const useCaseId = useCaseIds[idx];
          underlyingInfoMap[useCaseId] = data;
        }
      } else {
        logger.error("useFetchUseCaseUnderlyingInfo", "Error while fetching Copilot underlying info", result.reason);
      }
    });

    updateUnderlyingInfo(underlyingInfoMap);
    const nextState = getStateFromUnderlyingInfoMap(underlyingInfoMap);
    setState(nextState);
  }, [demoTenant, underlyingInfoRef, updateUnderlyingInfo, useCaseIds, useCasesMap]);

  return {
    ...state,
    fetchUnderlyingInfo
  };
};

type State = {
  isSuccess: boolean;
  isFetching: boolean;
  bizEntityTypes: BizEntityType[];
  entityTypeToEventTypeMap: Record<string, ResultEntity[]>;
  eventTypes: ResultEntity[];
  eventIdToEntityTypeMap: Record<string, BizEntityType[]>;
};

const getVerticalUseCaseUnderlyingInfo = (underlyingInfo: UseCaseUnderlyingInfo): VerticalUseCaseUnderlyingInfo => {
  if (!underlyingInfo) {
    return {
      bizEntityTypes: [],
      eventTypes: [],
      entityTypeToEventIds: {},
      eventIdToEntityTypes: {}
    };
  }

  const { entityTypeIds, entityTypeToEventId, eventIdToEntityType, eventIds } = underlyingInfo;

  const vUnderlyingInfo: VerticalUseCaseUnderlyingInfo = {
    bizEntityTypes: entityTypeIds?.resultEntityTypes || [],
    entityTypeToEventIds: {},
    eventIdToEntityTypes: {},
    eventTypes: eventIds?.resultEntities || []
  };

  Object.keys(entityTypeToEventId || {}).forEach(entityTypeId => {
    const eventTypeIds = underlyingInfo?.entityTypeToEventId[entityTypeId].resultEntities.map(re => re.entityId);
    vUnderlyingInfo.entityTypeToEventIds[entityTypeId] = eventTypeIds;
  });

  Object.keys(eventIdToEntityType || {}).forEach(eventId => {
    const entityTypeIds = underlyingInfo?.eventIdToEntityType[eventId].resultEntityTypes.map(re => re.entityTypeId);
    vUnderlyingInfo.eventIdToEntityTypes[eventId] = entityTypeIds;
  });

  return vUnderlyingInfo;
};

const getStateFromUnderlyingInfoMap = (underlyingInfoMap: Record<string, VerticalUseCaseUnderlyingInfo>): State => {
  const state: State = {
    isSuccess: true,
    isFetching: false,
    bizEntityTypes: [],
    entityTypeToEventTypeMap: {},
    eventTypes: [],
    eventIdToEntityTypeMap: {}
  };

  Object.keys(underlyingInfoMap).forEach(useCaseId => {
    const { bizEntityTypes, eventTypes, entityTypeToEventIds, eventIdToEntityTypes } = underlyingInfoMap[useCaseId];

    state.bizEntityTypes = [...state.bizEntityTypes, ...bizEntityTypes];
    state.eventTypes = [...state.eventTypes, ...eventTypes];

    Object.keys(entityTypeToEventIds).forEach(entityTypeId => {
      const eventTypeIds = entityTypeToEventIds[entityTypeId];
      eventTypeIds.forEach(eventTypeId => {
        const eventType = eventTypes.find(et => et.entityId === eventTypeId);
        if (eventType) {
          state.entityTypeToEventTypeMap[entityTypeId] = [
            ...(state.entityTypeToEventTypeMap[entityTypeId] || []),
            eventType
          ];
        }
      });
    });

    Object.keys(eventIdToEntityTypes).forEach(eventTypeId => {
      const entityTypeIds = eventIdToEntityTypes[eventTypeId];
      entityTypeIds.forEach(entityTypeId => {
        const bizEntityType = bizEntityTypes.find(et => et.entityTypeId === entityTypeId);
        if (bizEntityType) {
          state.eventIdToEntityTypeMap[eventTypeId] = [
            ...(state.eventIdToEntityTypeMap[eventTypeId] || []),
            bizEntityType
          ];
        }
      });
    });
  });

  state.bizEntityTypes = uniqBy(state.bizEntityTypes, be => be.entityTypeId);
  state.eventTypes = uniqBy(state.eventTypes, et => et.entityId);

  Object.keys(state.entityTypeToEventTypeMap).forEach(entityTypeId => {
    state.entityTypeToEventTypeMap[entityTypeId] = uniqBy(
      state.entityTypeToEventTypeMap[entityTypeId],
      et => et.entityId
    );
  });

  Object.keys(state.eventIdToEntityTypeMap).forEach(eventTypeId => {
    state.eventIdToEntityTypeMap[eventTypeId] = uniqBy(
      state.eventIdToEntityTypeMap[eventTypeId],
      et => et.entityTypeId
    );
  });

  return state;
};
